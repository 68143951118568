import "./App.css";
import "font-awesome/css/font-awesome.min.css";
import { useEffect, useState } from "react";
import img from "./images/1.jpg";
import img2 from "./images/2.jpg";
import img3 from "./images/3.jpg";

function App() {
  const [title, setTitle] = useState("");

  useEffect(() => {
    document.title = "Meta Lions Club";
    setTitle(document.title);
  }, [title]);
  return (
    <div>
      <section className="header" id="home">
        <nav>
          <div className="nav-links" id="navLinks">
            <ul>
              <li>
                <a href="#roadmap">ROADMAP</a>
              </li>
              <li>
                <a href="#nft">NFTS</a>
              </li>
              <li>
                <a href="#info">INFO</a>
              </li>
              <li>
                <a href="#store">STORE</a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="text-box">
          <h1>Meta Lions Club</h1>
          <p>Now available on DESO</p>
          <a
            className="hero-btn"
            target="blank"
            href="https://bitclout.com/u/metalionsclub"
          >
            {" "}
            Go to DESO Page
          </a>
        </div>
      </section>

      <section className="course" id="roadmap">
        <h1>Roadmap</h1>
        <div className="row">
          <div className="course-col" style={{ marginRight: "0.5em" }}>
            <h3>10%</h3>
            <p> FIRST 50 ITEMS ARE SOLD </p>
          </div>
          <div className="course-col" style={{ marginRight: "0.5em" }}>
            <h3>25%</h3>
            <p> BUY BACK REWARD FOR NFT HOLDERS </p>
          </div>
          <div className="course-col" style={{ marginRight: "0.5em" }}>
            <h3>50%</h3>
            <p>FIRST 250 ITEMS ARE SOLD</p>
          </div>
          <div className="course-col" style={{ marginRight: "0.5em" }}>
            <h3>65%</h3>
            <p>
              RELEASE OF FURTHER META LIONS CLUB RELATED PRODUCTS
            </p>
          </div>
          <div className="course-col" style={{ marginRight: "0.5em" }}>
            <h3>80%</h3>
            <p>
              ALL 500 ITEMS ARE SOLD
            </p>
          </div>
          <div className="course-col" style={{ marginRight: "0.5em" }}>
            <h3>100%</h3>
            <p>
             NFT HOLDERS WILL RECIEVE SOME ITEMS FROM THE STORE ACCORDING TO THEIR NFT COUNT.
            </p>
          </div>
        </div>
      </section>

      <section className="campus" id="nft">
        <h1> NFTS </h1>
        <p>500 of them will be minted on DESO</p>
        <div className="row">
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/12555415cb22db13ff027cc03a7ce89a554bb130070e7d6719431b68f1a862d4aea7dad2.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #001</h3>
            </div>
          </div>
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/3386341534c14158fe0e8345174ba176a4b3ac969c6ab144ff25f26ca9593c5847cea55e.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #002</h3>
            </div>
          </div>
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/7530235760924fb9eea5f08081f39416a8518f70c9a5112fd2806b783f466b35bd0d1bd2.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #003</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/126341411a24e08cb07c7ba4ba361c64e9ae1c794702521625fcfc5f17bcde095033495e.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #004</h3>
            </div>
          </div>
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/095595743be616eb03358476c066d773fda72c275510f0d26ac05caccbbacd72f666896c.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #005</h3>
            </div>
          </div>
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/7928911218cab43e20ee8154afec283ec7ba60b458eab54e2816440b40aefea63d2873e6.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #006</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/88801168472f7bc86a4749d62a174acd7bd4e6bf5fcf532917f9c40eca4fc5cc040df626.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #007</h3>
            </div>
          </div>
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/57455926ebbdba036a44e6b06e5f5994f1a59ab6effbe30c1eeae289938e84514c74f79d.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #008</h3>
            </div>
          </div>
          <div className="campus-col">
            <img
              src="https://serving.photos.photobox.com/093653990eb9f9afaa33c6386993ede0bb0fb88ce5b35e33c68706ac9abde9e8b4113fee.jpg"
              alt=""
            />
            <div className="layer">
              <h3>Meta Lions Club - #009</h3>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="infoRow" id="info">

          <h1>INFORMATION ABOUT THE PROJECT</h1>
          <p>
            Meta Lions Club collection is inspired by different crystals. Each
            unique lion has a personality that is expressed by its color and
            accessories: Sapphire (Blue) Lion is industrious, honest, and
            mentally strong. Citrine (Yellow) Lion has a positive attitude and
            optimism. Jade (Green) Lion brings harmony, attracts good luck and
            friendship. Quartz (Pink) Lion is very powerful in his/her beliefs,
            giving off a strong aura of confidence all around. Meta Lions Club
            collection is a collection of 500 unique NFTs living on the DESO
            Blockchain.
          </p>
        </div>
      </div>

      <section className="merchs" id="store">
        <h1>STORE</h1>
        <p>COMING SOON...</p>
        <div className="row">
          <div className="campus-col">
            <img src={img} alt="" />
            <div className="layer">
              <h3>T-Shirts</h3>
            </div>
          </div>
          <div className="campus-col">
            <img src={img2} alt="" />
            <div className="layer">
              <h3>Masks</h3>
            </div>
          </div>
          <div className="campus-col">
            <img src={img3} alt="" />
            <div className="layer">
              <h3>Hoodies</h3>
            </div>
          </div>
        </div>
      </section>

      <div className="tt-copy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 col-12">
              <div className="tt-copy-left">
                Copyright © company 2021. All rights reserved.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
